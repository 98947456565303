@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.info {
	padding-top: var(--form-info-pt, 8px);
	font-size: 14px;
	letter-spacing: normal;
	text-align: left;
	text-transform: none;
	cursor: text;
	font-family: $fontFutura;
	font-weight: 500;
	color: $red-1;

	&.onEnter {
		opacity: 0;
		transform: translateY(6px);
	}

	&.onEnterActive {
		opacity: 1;
		transform: translateY(0);
		transition: opacity 230ms, transform 230ms;
	}

	&.onExit {
		opacity: 1;
	}

	&.onExitActive {
		opacity: 0;
		transform: translateY(6px);
		transition: opacity 230ms, transform 230ms;
	}
}
